// general: 1/6 of the customer component - child of customer dash - (5th).

import React, { Component } from 'react'
// core files
import PacketActions from '../../../core/actions/packets/packetactions'
import Helper from '../../../core/helpers/platformhelper'
import LoadingSpinner from '../../helpers/loadingspinner'
// css styles
import '../../styles/universalstyles.scss'
import '../styles/customers.scss'

// global variable
let setTimeoutId

type Props = {
  DJtoken: string, // token to send to get the data.
  customerId: number, // sent for the customer's data.
  storeId: number, // sent for the store's data.
}

export default class CustomersHistory extends Component {
  constructor(props: Props) {
    super(props)

    this.state = {
      entries: [], // customer table json.results, if returned results.length > 0 else, remains empty.
      pagePrevious: null, // customer table json.previous
      pageNext: '1', // customer table json.next
      count: 0, // customer table json.count
      pageNum: 0, // page number variable that either gets -- or ++ based on this.state.count status
      loading: true, // loading value
    }
  }

  state: {
    entries: Object,
    pagePrevious: string,
    pageNext: string,
    count: number,
    pageNum: number,
    loading: boolean,
  }

  componentDidMount() {
    this.mounted = true // set 'mounted' to true.

    if (this.mounted) {
      // calls the initial customer action to load the real default data from the API
      this.CustomerHistoryGetAPIData('1')
    }
  }

  componentWillUnmount() {
    // ^resets all values and states and things.
    this.mounted = false

    // clear global timeout variable
    clearTimeout(setTimeoutId)

    // reset the states on unmount
    this.setState({
      entries: [],
      pagePrevious: null,
      pageNext: '1',
      count: 0,
      pageNum: 0,
      loading: true,
    })
  }

  // ***********************LETS*CALL*API***********************

  // CustomerHistoryGetAPIData
  // sends manipulated states to the API as the correct request params to get the returned customer data
  CustomerHistoryGetAPIData(pageDirection: string) {
    // on entry set the loading states
    this.setState({ loading: true })

    // props
    const { DJtoken, customerId } = this.props

    PacketActions.getCustomerHistoryPackets(customerId, pageDirection, DJtoken)
      .then(json => {
        if (json.results !== undefined && json.results && json.results.length > 0) {
          this.CustomerConfigureStates(json, pageDirection)
        } else {
          this.setState({ count: 0, loading: false })
        }
      })
      .catch(error => {
        this.CustomerConfigureStatesFailed()
        console.log(error.toString())
      })
  }
  // ***********************LETS*CALL*API***********************

  // ***********************LETS*CONFIGURE*THE*API***********************

  // CustomerConfigureStates
  CustomerConfigureStates(json: Object, pageDirection: string) {
    this.setState({
      entries: json.results,
      pagePrevious: json.previous !== null ? json.previous.split('query/packets/')[1] : null,
      pageNext: json.next !== null ? json.next.split('query/packets/')[1] : null,
      pageNum: pageDirection === '1' ? 0 : this.state.pageNum,
      count: json.count,
      loading: false,
    })
  }

  // CustomerConfigureStatesFailed
  CustomerConfigureStatesFailed() {
    this.setState({
      count: 0,
      loading: true,
    })
  }

  // ***********************LETS*CONFIGURE*THE*API***********************

  // ***********************LETS*SETUP*CONTAINERS***********************

  // CustomerTablePaginator
  CustomerTablePaginator() {
    const { count, pageNum, pageNext } = this.state
    const pagePrevBoolean = pageNum != 0 && count > 15
    const pageNextBoolean = pageNext === null

    if (count < 15) {
      return null
    }
    return (
      <div className="customerHistory-paginator">
        <button
          style={{
            color: pagePrevBoolean ? 'white' : 'grey',
            backgroundColor: pagePrevBoolean ? null : 'transparent',
            cursor: pagePrevBoolean ? 'pointer' : 'auto',
          }}
          onClick={() => {
            if (pagePrevBoolean) {
              this.CustomerHistoryGetAPIData(pageNum)
              this.setState({ pageNum: pageNum - 1 })
            }
          }}
        >
          Back
        </button>
        Page {pageNum + 1} of {Math.ceil(count / 15)}
        <button
          style={{
            color: !pageNextBoolean ? 'white' : 'grey',
            backgroundColor: !pageNextBoolean ? null : 'transparent',
            cursor: !pageNextBoolean ? 'pointer' : 'auto',
          }}
          onClick={() => {
            if (!pageNextBoolean) {
              this.CustomerHistoryGetAPIData(pageNum + 2)
              this.setState({ pageNum: pageNum + 1 })
            }
          }}
        >
          Next
        </button>
      </div>
    )
  }

  // CustomerTableContainer
  CustomerTableContainer() {
    // states
    const { entries, count, loading, pageNext } = this.state
    const { DJtoken, customerId } = this.props

    const pageNextBoolean = pageNext === null

    return (
      <div className="customer-table-box">
        <h2> Customer History</h2>
        {!loading ? (
          <div>
            <h3 align="center">{count === 0 ? 'No' : count} packets have been sent to this user</h3>
            <div className="customerHistory-table">
              {this.CustomerTablePaginator()}
              <div
                className="customerHistory-header"
                style={count ? { display: 'flex' } : { display: 'none' }}
              >
                <div className="customerHistory-info">Packet</div>
                <div className="customerHistory-viewed">Viewed</div>
                <div className="customerHistory-alerts">Alerts</div>
              </div>
              {entries.map(history => {
                return (
                  <div key={history.info} className="customerHistory-packet">
                    <div className="customerHistory-info">
                      <div className="customerHistory-infoCar">
                        <b>
                          {history.vehicle_id.year} {history.vehicle_id.make}{' '}
                          {history.vehicle_id.model}
                        </b>
                      </div>
                      <div className="customerHistory-infoUser">
                        <span>User:</span> {history.user_id_fk.first_name}{' '}
                        {history.user_id_fk.last_name}
                      </div>
                      <div className="customerHistory-infoSent">
                        <span>Sent:</span> {Helper.FormatDateUSA(history.created_dt)}
                      </div>
                      <div className="customerHistory-infoVersion">
                        <span>Version:</span> {history.source.client_display_label}
                      </div>
                    </div>
                    <div className="customerHistory-viewed">
                      {history.viewed_dt != null ? 'Yes' : 'No'}
                    </div>
                    <div className="customerHistory-alerts">
                      <button
                        className={
                          history.disable_pc_alerts
                            ? 'alerts-switch-btn alerts-switch-on'
                            : 'alerts-switch-btn alerts-switch-off'
                        }
                        style={
                          history.disable_pc_alerts
                            ? { backgroundColor: '#F83030' }
                            : { backgroundColor: '#3CB54A' }
                        }
                        onClick={() => {
                          PacketActions.postUpdateCustomerPacketAlert(
                            customerId,
                            history.packet_history.vehicle_id,
                            !history.disable_pc_alerts,
                            history.share_uuid,
                            DJtoken,
                          )
                            .then(json => {
                              if (history.disable_pc_alerts != json.disable_pc_alerts) {
                                history.disable_pc_alerts = json.disable_pc_alerts
                                this.forceUpdate()
                              }
                            })
                            .catch(error => {
                              console.log(error.toString())
                            })
                        }}
                      >
                        {history.disable_pc_alerts ? 'Off' : 'On'}
                      </button>
                    </div>
                  </div>
                )
              })}
              {!pageNextBoolean ? this.CustomerTablePaginator() : null}
            </div>
          </div>
        ) : (
          <LoadingSpinner loading wheel />
        )}
      </div>
    )
  }

  // ***********************LETS*SETUP*CONTAINERS***********************

  props: Props

  render() {
    return (
      <div className="customersMainStyle" align="center">
        {this.CustomerTableContainer()}
      </div>
    )
  }
}
