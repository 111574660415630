import AIP_DJAPI, { AIP_DJAPI_URLS } from '../config/aipDjapi'

function getAccidentHistoryOptions() {
  let headers = {
    Accept: 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoAccidentHistoryOptionsVehicleAppraisal}`, {
    method: 'GET',
    headers,
  })
}

function initiateVehicleAppraisal(
  vin,
  zip_code,
  mileage,
  collateral_value,
  accident_history_id,
  mileage_range,
  oem_certified,
) {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  let params = {
    zip_code,
    mileage: mileage ? mileage : 0,
    collateral_value: collateral_value ? collateral_value : 0,
    accident_history_id,
    mileage_range: mileage_range ? mileage_range : 0,
    oem_certified,
  }

  return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoInitiateVehicleAppraisal(vin)}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(params),
  })
}

function pollVehicleAppraisal(appraisalId) {
  let headers = {
    Accept: 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoPollVehicleAppraisal(appraisalId)}`, {
    method: 'GET',
    headers,
  })
}

function getVehicleAppraisalComparables(appraisalId) {
  let headers = {
    Accept: 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoComparablesVehicleAppraisal(appraisalId)}`, {
    method: 'GET',
    headers,
  })
}

function putVehicleAppraisalFeedback(appraisalId, user_trade_in_value) {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  let params = { user_trade_in_value }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoFeedbackVehicleAppraisal(appraisalId)}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(params),
  })
}

function getVehicleAppraisalFeedback(appraisalId) {
  let headers = {
    Accept: 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoFeedbackVehicleAppraisal(appraisalId)}`, {
    method: 'GET',
    headers,
  })
}

function getAvailableVehicleAppraisalTradeAdjustmentFields() {
  let headers = {
    Accept: 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoAvailableTradeAdjustmentFieldsVehicleAppraisal}`, {
    method: 'GET',
    headers,
  })
}

function putVehicleAppraisalTradeAdjustmentFields(appraisalId, trade_adjustment_fields) {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  let params = { trade_adjustment_fields }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoTradeAdjustmentFieldsVehicleAppraisal(appraisalId)}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(params),
  })
}

export default {
  getAccidentHistoryOptions,
  initiateVehicleAppraisal,
  pollVehicleAppraisal,
  getVehicleAppraisalComparables,
  putVehicleAppraisalFeedback,
  getVehicleAppraisalFeedback,
  getAvailableVehicleAppraisalTradeAdjustmentFields,
  putVehicleAppraisalTradeAdjustmentFields,
}
