// general: 1/6 of the customer component - child - (2nd).

// TODO:
// 1. there is 1 other 'todo' inside the file.
// 2. mobile support
// 3. cross browser check support

// @flow
import React, { Component } from 'react'
// node packages
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-table/react-table.css'
import _ from 'lodash'
import BottomScrollListener from 'react-bottom-scroll-listener'
// core files
import CustomerActions, {
  getRecentCustomers,
  getFavoriteCustomers,
} from '../../../core/actions/users/customeractions'
// images
import IconAddCustomer from '../../../core/assets/images/icons/icon-add-customer'
import NoContactIcon from '../../../core/assets/images/icons/no-contact'
import SearchIcon from '../../inventory/assets/searchicon.js'
import IconCancel from '../../../core/assets/images/icons/icon-x'
// helpers
import LoadingSpinner from '../../helpers/loadingspinner'
// actions
import { showModal, hideModal } from '../../../core/_actions/modalActions'
import { CUSTOMER_FORM_MODAL } from '../../../core/_constants/modalTypes'
import {
  stashViewedCustomer,
  stashCustomerTableState,
} from '../../../core/_actions/customerstashActions'
// components
import CustomerForm from '../../modals/customers_modals/customerform'
import UserInfoWrapper from '../../../core/wrappers/userinfowrapper'
import CustomersRow from './customersrow'
// css styles
import '../../styles/universalstyles.scss'
import '../styles/customers.scss'

// global variable
let setTimeoutId
// ***********************************************************************************
type Props = {
  DJtoken: string, // token to send to get the data.
  storeId: number, // sent for the table data.
}

class CustomersTable extends Component {
  constructor(props: Props) {
    super(props)
    this.state = {
      recentCustomers: null,
      favoriteCustomers: null,
      entries: [],
      noResults: false,
      page: 1,
      search: '',
      activeSearch: '',
      loading: 1,
      endOfResults: false,
      scrollLoad: false,
      customers: [],
      creatingCustomer: false,
      duplicateCustomer: null,
      editingCustomer: -1,
      retainedCustomerBeingEdited: null,
      hasError: false,
      errorMessage: '',
    }
    this.updateCustomers = this.updateCustomers.bind(this)
  }

  state: {
    entries: Object,
    noResults: boolean,
    count: number,
    page: number,
    search: string,
    loading: number,
    endOfResults: boolean,
    scrollLoad: boolean,
    activeSearch: string,
    duplicateCustomer: object,
    editingCustomer: number,
    retainedCustomerBeingEdited: object,
    hasError: boolean,
    errorMessage: string,
  }

  onMountGetRecent() {
    let { userInfo } = this.props
    getRecentCustomers({ token: userInfo.dj_token }).then(res => {
      if (res && res.results) {
        this.setState({ recentCustomers: res.results })
      }
    })
  }
  onMountGetFavorite() {
    let { userInfo } = this.props
    getFavoriteCustomers({ token: userInfo.dj_token }).then(res => {
      if (res && res.results) {
        this.setState({ favoriteCustomers: res.results })
      }
    })
  }
  componentDidMount() {
    this.mounted = true // set 'mounted' to true.

    if (this.mounted) {
      // sets dummy data onmount into the table for row init
      this.onMountGetRecent()
      // calls the initial customer action to load the real default data from the API
      //this.CustomerGetAPIData('normal', '1', '', '')
      this.onMountGetFavorite()
      // calls the initial customer action to load the real default data from the API
      //this.CustomerGetAPIData('normal', '1', '', '')
    }

    let { storeId } = this.props

    if (storeId > -1) {
      this.setState(
        {
          entries: [],
          page: 1,
          loading: 1,
          endOfResults: false,
          scrollLoad: false,
        },
        () => {
          this.CustomerGetAPIData(false)
        },
      )
    }
  }

  componentDidUpdate(prevProps) {
    let { storeId } = this.props

    if (storeId > -1 && storeId !== prevProps.storeId) {
      this.setState(
        {
          entries: [],
          page: 1,
          loading: 1,
          endOfResults: false,
          scrollLoad: false,
        },
        () => {
          this.CustomerGetAPIData(false)
        },
      )
    }
  }

  componentWillUnmount() {
    // ^resets all values and states and things.
    this.mounted = false

    // clear global timeout variable
    clearTimeout(setTimeoutId)

    // reset the states on unmount
    this.setState({
      recentCustomers: [],
      favoriteCustomers: [],
      entries: [],
      noResults: false,
      count: 0,
      page: 1,
      search: '',
      activeSearch: '',
      loading: 0,
      endOfResults: false,
      scrollLoad: false,
      customers: [],
      creatingCustomer: false,
      duplicateCustomer: null,
      editingCustomer: -1,
      retainedCustomerBeingEdited: null,
      hasError: false,
      errorMessage: '',
    })
  }

  componentWillReceiveProps(newProps) {
    const { search } = this.state
    const { modalCheck } = this.props
    if (modalCheck) {
      this.CustomerUpdateField(search)
      this.forceUpdate()
    }
  }

  submit = (customer, customerId) => {
    const { userInfo, hideModal } = this.props
    const { customers, editingCustomer, retainedCustomerBeingEdited } = this.state
    if (customerId) {
      CustomerActions.putUpdateSingleContact(
        customerId,
        customer.first_name,
        customer.last_name,
        customer.email,
        customer.phone_number,
        customer.mobile_number,
        customer.address1,
        customer.city,
        customer.state,
        customer.postal_code,
        userInfo.dj_token,
        customer.is_favorite,
      ).then(res => {
        if (res.msg || res.detail || res.non_field_errors) {
          const errorMessage =
            res.detail != '' ? res.detail : res.msg != '' ? res.msg : res.non_field_errors
          this.setState({ hasError: true, errorMessage })
          toast(errorMessage)
        } else {
          this.props.stashViewedCustomer(res)
          this.props.stashCustomerTableState(null)
          toast(`Successfully Edited Contact ${customer.first_name}`)
          this.setState({ creatingCustomer: false, editingCustomer: -1 })
        }
      })
      if (customer.is_favorite) {
        CustomerActions.putCreateFavoriteCustomer(
          customerId,
          userInfo.user_id,
          userInfo.dj_token,
        ).then(res => {})
      } else {
        CustomerActions.putDeleteFavoriteCustomer(
          customerId,
          userInfo.user_id,
          userInfo.dj_token,
        ).then(res => {})
      }
    } else {
      this.setState({ submitting: true })
      CustomerActions.putCreateSingleContact(
        userInfo.company_id,
        customer.first_name,
        customer.last_name,
        customer.email,
        customer.phone_number,
        customer.mobile_number,
        customer.address1,
        customer.city,
        customer.state,
        customer.postal_code,
        userInfo.dj_token,
        customer.is_favorite,
      ).then(res => {
        try {
          if (res.message && res.existing_customer && res.requested_customer) {
            if (this.customerEqualEnough(res.existing_customer, res.requested_customer)) {
              customers.push(res.existing_customer)
              // temporarily overriding API response while we work on duplication comparitor
              toast('This customer already exists.')
            } else {
              toast('Please resolve conflicting information.')
              customers.push(res.existing_customer)
              this.setState({
                customers,
                duplicateCustomer: res.requested_customer,
                editingCustomer: customers.length - 1,
                retainedCustomerBeingEdited: _.clone(res.existing_customer),
                hasError: false,
                errorMessage: '',
              })
            }
            this.setState({ creatingCustomer: false, customers })
          } else if (res.msg || res.detail || res.non_field_errors) {
            const errorMessage =
              res.detail != '' ? res.detail : res.msg != '' ? res.msg : res.non_field_errors

            this.setState({ hasError: true, errorMessage })

            if (errorMessage != undefined) {
              toast(errorMessage)
            }
          } else {
            customers.push(res)
            this.setState({ creatingCustomer: false, customers })
            this.updateCustomers(res, customer.is_favorite)
            toast(
              `${res.message ? res.message : `Successfully Created Customer ${res.first_name}`}`,
            )
          }
          if (customer.is_favorite) {
            CustomerActions.putCreateFavoriteCustomer(
              res.customer_id,
              userInfo.user_id,
              userInfo.dj_token,
            ).then(res => {})
          }
        } catch (e) {
          toast('There was an issue creating customer. Please try again')
        }
      })
    }
  }

  customerEqualEnough(existing, entered) {
    existing.mobile_number = existing.mobile_number ? existing.mobile_number : ''
    existing.email = existing.email ? existing.email : ''

    const equal =
      (existing.email === entered.email &&
        (entered.mobile_number.replace(/[- )(]/g, '') ===
          existing.mobile_number.replace(/[- )(]/g, '') ||
          entered.mobile_number.replace(/[- )(]/g, '').length === 0)) ||
      (existing.mobile_number.replace(/[- )(]/g, '') ===
        entered.mobile_number.replace(/[- )(]/g, '') &&
        (entered.email === existing.email || entered.email === ''))
    return equal
  }

  cancel = () => {
    // needs to abort changes to customers by overwriting customer being edited
    // with the retained customer on cancel
    const resetCustomers = _.clone(this.state.customers)
    resetCustomers[this.state.editingCustomer] = this.state.retainedCustomerBeingEdited
    this.setState({
      duplicateCustomer: null,
      customers: resetCustomers,
      creatingCustomer: false,
      editingCustomer: -1,
      retainedCustomerBeingEdited: null,
    })
  }

  closeCustomerForm = () => {
    this.setState({ creatingCustomer: false, editingCustomer: -1 })
  }

  updateCustomers(customer, isFavorite) {
    let newRecents = JSON.parse(JSON.stringify(this.state.recentCustomers))
    let inRecents = false
    newRecents.forEach(function(item) {
      if (item.customer_id === customer.customer_id) inRecents = true
    })
    if (!inRecents) {
      newRecents.push(customer)
      newRecents = [...new Map(newRecents.map(item => [item['customer_id'], item])).values()]
      this.setState({ recentCustomers: newRecents })
    }
    // when favoriting a customer...
    if (isFavorite) {
      let newFavorites = JSON.parse(JSON.stringify(this.state.favoriteCustomers))
      newFavorites.push({ customer })
      newFavorites = [
        ...new Map(newFavorites.map(item => [item['customer']['customer_id'], item])).values(),
      ]
      this.setState({ favoriteCustomers: newFavorites })
    }
    // when un-favoriting a customer...
    if (!isFavorite) {
      const favorites = JSON.parse(JSON.stringify(this.state.favoriteCustomers))
      const newFavorites = favorites.filter(
        obj => obj.customer.customer_id !== customer.customer_id,
      )
      this.setState({ favoriteCustomers: newFavorites })
    }
  }

  scrollSearch = () => {
    const { loading, search, endOfResults } = this.state
    if (!loading && search.length && !endOfResults) {
      this.setState({ scrollLoad: true })
      this.CustomerGetAPIData(true)
    }
  }

  // ***********************LETS*CALL*API***********************
  // searchContinued prop is a boolean indicating if search is paginating beyond pg 1
  CustomerGetAPIData = searchContinued => {
    let { search, entries, endOfResults, page } = this.state
    const { DJtoken, storeId } = this.props
    if (!searchContinued) {
      this.setState({ loading: 1 })
      CustomerActions.getSearchContacts(storeId, search, page, DJtoken).then(res => {
        if (!res.results) {
          this.setState({ noResults: true })
        } else if (res && res.results) {
          this.setState({
            entries: res.results,
            endOfResults:
              !res.results.length || (res.results.length <= 50 && res.count <= 50) ? true : false,
            loading: 0,
            scrollLoad: false,
            activeSearch: search,
          })
        }
      })
    } else if (searchContinued) {
      const nextPage = page + 1
      CustomerActions.getSearchContacts(storeId, search, nextPage, DJtoken).then(res => {
        if (res && res.results) {
          entries = entries.concat(res.results)
          if (!res.results.length || (res.results.length <= 50 && res.count <= 50)) {
            endOfResults = true
          }
          this.setState({
            entries,
            page: nextPage,
            endOfResults,
            loading: 0,
            scrollLoad: false,
          })
        }
      })
    }
  }

  // ***********************LETS*CONFIGURE*THE*API***********************
  CustomerConfigureStatesFailed() {
    this.setState(prevState => {
      return {
        count: 0,
        loading: prevState.loading - 1,
        loadingText: 'There was an error loading.',
      }
    })
  }

  // ***********************LETS*DO*FUNCTIONS***********************
  // - CustomerUpdateField updates the 'search' state on keystrokes.
  // - there is a timeout here for fast typers.
  CustomerUpdateField(search: string) {
    // clear global timeout variable
    clearTimeout(setTimeoutId)
    this.setState({ search })
    if (search == '') {
      this.setState(
        {
          search: '',
          activeSearch: '',
          entries: [],
          page: 1,
          loading: 1,
          endOfResults: false,
          scrollLoad: false,
        },
        () => {
          this.CustomerGetAPIData(false)
        },
      )
    } else {
      this.setState({
        search,
        activeSearch: search,
        entries: [],
        page: 1,
        loading: 1,
        endOfResults: false,
        scrollLoad: false,
      })
      setTimeoutId = setTimeout(() => {
        this.CustomerGetAPIData(false)
      }, 400)
    }
  }

  renderSearch = () => {
    let { search } = this.state
    return (
      <div className="customer-search-box">
        <input
          id="customers-search-input"
          className="customer-form-control-override"
          placeholder="Type here to begin your search"
          type="text"
          value={search}
          onChange={e => {
            this.CustomerUpdateField(e.target.value)
          }}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              this.CustomerGetAPIData(false)
            }
          }}
        />
        {search.length === 0 ? (
          <div
            className="customers-search-button"
            onClick={() => {
              this.CustomerGetAPIData(false)
            }}
          >
            <SearchIcon />
          </div>
        ) : (
          <div
            className="customers-search-reset"
            onClick={() => {
              this.setState(
                {
                  search: '',
                  activeSearch: '',
                  entries: [],
                  page: 1,
                  loading: 1,
                  endOfResults: false,
                  scrollLoad: false,
                },
                () => {
                  this.CustomerGetAPIData(false)
                },
              )
            }}
          >
            <IconCancel />
          </div>
        )}
      </div>
    )
  }

  // ***********************LETS*SETUP*CONTAINERS***********************
  CustomersTopContainer() {
    const { search } = this.state

    return (
      <div>
        <label htmlFor="customer-form-control-override" style={{ display: 'none' }}>
          Contacts Search Bar Area
        </label>
        {this.renderSearch()}
      </div>
    )
  }

  customerAddButton() {
    return (
      <div className="customer-add-box">
        <button
          className="btn-add-customer"
          onClick={() => {
            this.setState({ creatingCustomer: true })
          }}
        >
          <div>Add New Contact</div>
          <IconAddCustomer />
        </button>
      </div>
    )
  }

  renderNoCustomerDisplay() {
    return (
      <div className="customer-display-container" style={{ animation: ` 1500ms fadeIn` }}>
        <button
          onClick={() => {
            this.props.showModal(CUSTOMER_FORM_MODAL, { displayFullForm: true })
          }}
          className="no-contact-button"
        >
          <NoContactIcon />
        </button>
        <div>
          <h4 className="no-contact-header">Click to Add New Contact</h4>
        </div>
      </div>
    )
  }

  renderRecentCustomers() {
    const { recentCustomers } = this.state
    if (recentCustomers.length) {
      return (
        <React.Fragment>
          <h1>Recent Customers</h1>
          <hr />
          <div className="customer-display-container" style={{ animation: ` 1500ms fadeIn` }}>
            {recentCustomers.map(customer => (
              <CustomersRow
                customer={customer}
                key={customer.customer_id}
                update={this.updateCustomers}
              />
            ))}
          </div>
          <br />
        </React.Fragment>
      )
    }
    return <React.Fragment />
  }
  renderFavoriteCustomers() {
    const { favoriteCustomers } = this.state
    if (favoriteCustomers.length) {
      return (
        <React.Fragment>
          <h1>Favorite Customers</h1>
          <hr />
          <div className="customer-display-container" style={{ animation: ` 1500ms fadeIn` }}>
            {favoriteCustomers.map(customer => (
              <CustomersRow
                customer={customer.customer}
                key={customer.customer.customer_id}
                update={this.updateCustomers}
              />
            ))}
          </div>
        </React.Fragment>
      )
    }
    return <React.Fragment />
  }
  renderCustomers() {
    const { entries, loading, noResults } = this.state
    if (noResults && !loading && !entries.length) {
      return (
        <React.Fragment>
          <hr />
          <div>No Results Found</div>
        </React.Fragment>
      )
    }
    if (loading) {
      return (
        <div>
          <LoadingSpinner loading wheel />
        </div>
      )
    }
    if (entries.length) {
      return (
        <React.Fragment>
          <BottomScrollListener onBottom={this.scrollSearch} />
          <hr />
          <div className="customer-display-container" style={{ animation: ` 1500ms fadeIn` }}>
            {entries.map(customer => (
              <CustomersRow key={customer.customer_id} customer={customer} />
            ))}
          </div>
          {this.renderLoadMore()}
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <hr />
        <div>No Results Found</div>
      </React.Fragment>
    )
  }

  renderLoadMore = () => {
    const { loading, search, endOfResults, scrollLoad } = this.state
    if (!loading && search.length && !endOfResults) {
      return (
        <button
          className="archived-packet-load-more"
          onClick={() => {
            this.setState({ scrollLoad: true })
            this.CustomerGetAPIData(true)
          }}
        >
          {scrollLoad ? (
            <LoadingSpinner timeout={120000} loading />
          ) : (
            <React.Fragment>Load More</React.Fragment>
          )}
        </button>
      )
    }
  }

  render() {
    const {
      search,
      recentCustomers,
      favoriteCustomers,
      customers,
      creatingCustomer,
      editingCustomer,
      duplicateCustomer,
      hasError,
      errorMessage,
    } = this.state
    return (
      <div className="customersMainStyle">
        <div
          className={`customer-table-box ${
            creatingCustomer || editingCustomer >= 0 ? 'dimmed' : ''
          }`}
        >
          {search.length == 0 ? (
            <div>
              <h1>Find a Contact</h1>
              <h4>
                Enter a contact's partial name, email, or phone number in the search bar to find
                them.
              </h4>
            </div>
          ) : null}
          {this.CustomersTopContainer()}
          {((recentCustomers && recentCustomers.length > 0) ||
            (favoriteCustomers && favoriteCustomers.length > 0)) &&
            this.customerAddButton()}
          {creatingCustomer && (
            <div className="send-modal-add-block contact-pg-form">
              <CustomerForm
                displayFullForm={true}
                submit={this.submit}
                cancel={this.cancel}
                hasError={hasError}
                errorMessage={errorMessage}
              />
            </div>
          )}
          {editingCustomer >= 0 && (
            <div className="send-modal-add-block contact-pg-form">
              <CustomerForm
                customer={customers[editingCustomer]}
                duplicateCustomer={duplicateCustomer}
                customerId={customers[editingCustomer].customer_id}
                submit={this.submit}
                cancel={this.cancel}
                hasError={hasError}
                errorMessage={errorMessage}
              />
            </div>
          )}
          {recentCustomers && favoriteCustomers ? (
            <div className="customer-results">
              {search.length === 0 &&
                recentCustomers.length === 0 &&
                favoriteCustomers.length === 0 &&
                this.renderNoCustomerDisplay()}
              {search.length === 0 && recentCustomers.length > 0 && this.renderRecentCustomers()}
              {search.length === 0 &&
                favoriteCustomers.length > 0 &&
                this.renderFavoriteCustomers()}
              {search.length > 0 && this.renderCustomers()}
            </div>
          ) : null}
        </div>
      </div>
    )
  }
  props: Props
  _CustomerUpdateField: Function // function
  _inputSearch: Function // ref
}
const mapStateToProps = state => ({
  modalCheck: state.modal.type,
  viewed_customer: state.customerstash.viewed_customer,
  stashed_table_state: state.customerstash.table_state,
})
export default connect(mapStateToProps, {
  hideModal,
  showModal,
  stashViewedCustomer,
  stashCustomerTableState,
})(UserInfoWrapper(CustomersTable))
