// general: team member edit modal.

// TODO:
// 1. Rework '---' string pattern in dropdowns

// @flow
import React, { Component } from 'react'
// node packages
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { toast } from 'react-toastify'
import Collapsible from 'react-collapsible'
// core files
// --- actions
import AdminActions from '../../../core/actions/users/adminactions'
import UserActions, { initiateResetPassword } from '../../../core/actions/users/useractions'
import ProfileActions from '../../../core/actions/users/profileactions'
// --- data
import TeamEditInputSeed from '../../../core/data/AIPSeedsData'
// --- helpers
import LoadingSpinner from '../../helpers/loadingspinner'
import Helper from '../../../core/helpers/platformhelper'
import { hideModal } from '../../../core/_actions/modalActions'
// components
import Modal from '../../layouts/modal'
import MaskedInput from 'react-text-mask'
import EditIcon from '../../../core/assets/images/icons/editicon'

// images
import GreenCheck from '../../../core/assets/images/icons/icon-green-checkmark.png'
import IconDot from '../../../core/assets/images/icons/icon-dot'
import IconEyeOpen from '../../../core/assets/images/icons/icon-eye-open'
// css styles
import '../../styles/universalstyles.scss'
import '../styles/teamMemberModal.scss'

type Props = {
  userId: number,
  storeId: number,
  userEdit: boolean,
  userAdd: boolean,
  token: string,
  hideModal: boolean,
}

export class TeamMemberEditModalComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      userId: this.props.userId,
      username: '',
      user_email_address: '',
      crm_email: '',
      first_name: '',
      last_name: '',
      user_phone: '',
      user_mobile: '',
      password: '',
      confirmPassword: '',
      passwordCompliance: 0,
      passwordRequirements: [],
      passwordStrength: 0,
      passwordVisible: false,
      formBaseline: {
        username: '',
        user_email_address: '',
        crm_email: '',
        first_name: '',
        last_name: '',
        user_phone: '',
        user_mobile: '',
        storeId: '',
        send_mobile_alerts: true,
        send_email_alerts: true,
        viewFrequency: '',
      },

      storeList: [],
      storeId: this.props.storeId,
      storeName: 'Choose default store',
      dropStore: '',

      userRoleList: [],
      userRoleId: null,
      userRoleName: 'Choose default role',
      dropRole: '',

      send_mobile_alerts: true,
      send_email_alerts: true,
      viewFrequency: 'all',
      apiMessageIsPositive: false,
      apiMessage: '',
      formError: false,

      formModified: false,
      emailTipVisible: false,
      loading: this.props.userAdd ? false : true,
      resetAttempted: false,
      resetSuccessful: false,
    }

    this._onClose = this.onClose.bind(this)
    this._onUpdateField = this.onUpdateField.bind(this)
    this._onSubmit = this.onSubmit.bind(this)
  }

  state: {
    userId: number,
    username: string,
    user_email_address: string,
    crm_email: string,
    first_name: string,
    last_name: string,
    user_phone: string,
    user_mobile: string,

    storeList: Object,
    storeId: number,
    storeName: string,
    dropStore: string,

    send_mobile_alerts: boolean,
    send_email_alerts: boolean,
    viewFrequency: string,

    apiMessage: string,
    formError: boolean,

    loading: boolean,
  }

  componentDidMount() {
    this.mounted = true

    if (this.mounted) {
      if (!this.props.userAdd) {
        this.onMountGetUserInfo()
      } else {
        this.onMountGetDefaultStore(this.props.storeId, this.props.token)
        this.setState({
          dropRole: 'Choose Role---null',
        })
      }

      this.onMountGetStoreList()
      this.onMountGetUserRoles()
    }
  }

  // componentWillUnmount
  // - resets all values and states and things.
  componentWillUnmount() {
    this.mounted = false

    this.setState({
      userId: null,

      username: '',
      user_email_address: '',
      crm_email: '',
      first_name: '',
      last_name: '',
      user_phone: '',
      user_mobile: '',

      storeList: [],
      storeId: null,
      storeName: '',
      dropStore: '',

      send_mobile_alerts: true,
      send_email_alerts: true,
      viewFrequency: '',
      apiMessageIsPositive: false,
      apiMessage: '',
      formError: false,

      formModified: false,
      emailTipVisible: false,
      loading: true,
    })
  }

  // ***********************LETS*CALL*ONMOUNT*SETUP***********************
  // onMountGetCustomerInfo
  onMountGetUserInfo() {
    if (this.props.userId !== null && this.props.userId !== undefined) {
      AdminActions.getAdminMember(this.props.userId, this.props.token).then(json => {
        if (json.legacy_user_id !== null && json.legacy_user_id !== undefined) {
          console.log('LOOK HERE LOOK HERE')
          console.log(json)
          const token = this.props.token
          // get out ahead of null input in react and convert to empty string
          if (json.crm_email == null) {
            json.crm_email = ''
          }
          this.setState({
            username: json.username,
            user_email_address: json.user_email_address,
            crm_email: json.crm_email != null ? json.crm_email : '',
            first_name: json.first_name,
            last_name: json.last_name,
            user_phone: json.user_phone,
            user_mobile: json.user_mobile,
            storeId: json.default_store_id,
            send_mobile_alerts: json.send_mobile_alerts,
            send_email_alerts: json.send_email_alerts,
            viewFrequency: json.view_frequency !== '' ? json.view_frequency : 'first',
            loading: false,
            formBaseline: json,
          })

          if (json.default_store_id) {
            console.log('IN DEFAULT ID IF')
            this.onMountGetDefaultStore(json.default_store_id, token)
          } else {
            this.setState({ storeName: 'Choose default store' })
          }
        }
      })
    }
  }

  matchIdToName(providedId, objectArr, keyToMatch) {
    const matchingItem = objectArr.find(x => x[keyToMatch] == providedId)
    return matchingItem
  }

  // onMountGetStoreList
  onMountGetStoreList() {
    if (this.props.userId !== null && this.props.userId !== undefined) {
      ProfileActions.getUserStoreGroups(this.props.userId, this.props.token).then(json => {
        if (json && json.length > 0) {
          this.setState({ storeList: json })
        }
      })
    }
  }

  // onMountGetDefaultStore
  // Pre-Owned Mercedes-Benz of Fayetteville---29

  onMountGetDefaultStore(id: number, token: string) {
    ProfileActions.getUserDefaultStoreInfo(id, token).then(json => {
      if (json.store_full_name !== undefined) {
        console.log('DEFAULT STORE INFO')
        console.log(json)
        this.setState({
          storeName: json.store_full_name,
          dropStore: `${json.store_type_id === 1 ? 'New' : 'Pre-Owned'} ${json.store_name}---${
            json.company_store_id
          }`,
        })
      }
    })
  }

  // onMountGetUserRoles
  onMountGetUserRoles() {
    ProfileActions.getUserRoleList(this.props.token).then(json => {
      if (json.results && json.results.length > 0) {
        this.setState({ userRoleList: json.results })
        if (json.results && json.results.length > 0) {
          this.onMountGetDefaultUserRole(this.props.userId, this.props.token, json.results)
        }
      }
    })
  }

  // onMountGetDefaultUserRole
  onMountGetDefaultUserRole(id: number, token: string, list: Object) {
    if (!this.props.userAdd) {
      ProfileActions.getSingleUserRole(id, token).then(json => {
        if (json.role_id !== undefined) {
          this.setState({ userRoleId: json.role_id })
          for (const d of list) {
            if (d.role_id === json.role_id) {
              this.setState({
                userRoleName: d.role_name,
                dropRole: `${d.role_name}---${json.role_id}`,
              })
            }
          }
        }
      })
    } else {
      this.setState({ dropRole: 'Choose Role---null' })
    }
  }
  // ***********************LETS*CALL*ONMOUNT*SETUP***********************

  //
  // ***********************LETS*PRESS*THE*BUTTON***********************
  // JsonHandler
  JsonHandler(isPositive: boolean, message: string, userId) {
    const id = userId ? userId : this.state.userId
    const apiMessageTemperament = message.includes('reset') ? true : false
    // TODO: wrap and extend Toasts!
    toast(message, {
      autoClose: 7000,
    })
    if (isPositive) {
      this.CheckUserRole(id, this.state.userRoleId)
      this.Bye(message)
    } else {
      this.setState({
        apiMessage: message,
        formError: true,
        loading: false,
        apiMessageIsPositive: apiMessageTemperament,
      })
    }
  }

  // onSubmit
  onSubmit() {
    this.setState({ loading: true })

    // props
    const { userId, userEdit, userAdd, token } = this.props

    // states
    const {
      user_email_address,
      crm_email,
      first_name,
      last_name,
      user_phone,
      user_mobile,
      storeId,
      send_mobile_alerts,
      send_email_alerts,
      viewFrequency,
      emailTipVisible,
    } = this.state

    // booleans
    // --- general
    const first_nameBoolean = first_name !== null && first_name !== undefined && first_name !== ''
    const last_nameBoolean = last_name !== null && last_name !== undefined && last_name !== ''
    const mobileBoolean = user_mobile !== null && user_mobile !== undefined && user_mobile !== ''
    const emailBoolean =
      user_email_address !== null && user_email_address !== undefined && user_email_address !== ''

    // valid types
    // --- general
    const emailIsValid = emailBoolean && Helper.CheckEmailValidation(user_email_address)
    const mobileIsValid = mobileBoolean && Helper.CheckMobileValidation(user_mobile)

    if (first_nameBoolean && last_nameBoolean && (emailIsValid || mobileIsValid)) {
      const params = {
        first_name: first_name,
        last_name: last_name,
        //user_email_address: user_email_address,
        crm_email: crm_email != '' ? crm_email : null,
        user_phone: user_phone,
        user_mobile: user_mobile != '(___) ___-____' ? user_mobile : '',
        send_mobile_alerts: send_mobile_alerts,
        send_email_alerts: send_email_alerts,
        view_frequency: viewFrequency,
        default_store_id: storeId,
      }
      // include email when user is being created
      if (userAdd) {
        params.user_email_address = user_email_address
      }
      // send email when email has been modified
      if (
        this.state.emailTipVisible &&
        user_email_address != this.state.formBaseline.user_email_address
      ) {
        params.user_email_address = user_email_address
      }
      if (userEdit) {
        this.EditUser(userId, params, token, first_name, last_name)
      } else if (userAdd) {
        params.role_id = this.state.userRoleId
        console.table(params)

        AdminActions.putAdminMember(params, token)
          .then(json => {
            if (json.msg !== 'User Already Exists - Deactivated') {
              if (json.legacy_user_id) {
                this.JsonHandler(
                  true,
                  `User ${first_name} ${last_name} Successfully Created`,
                  json.legacy_user_id,
                )
              } else if (json.associated_legacy_user) {
                this.JsonHandler(
                  true,
                  `User ${first_name} ${last_name} Successfully Reactivated`,
                  json.associated_legacy_user.legacy_user_id,
                )
              } else if (
                json.non_field_errors[0] !== null &&
                json.non_field_errors[0] !== undefined
              ) {
                this.JsonHandler(false, `${json.non_field_errors[0]}`)
              } else {
                this.JsonHandler(
                  false,
                  `Unable to save this profile. Check your selections and try again.`,
                )
              }
            } else {
              delete params['role_id']
              this.setState({ userId: json.legacy_user_id })
              params.active = true
              this.EditUser(json.legacy_user_id, params, token, first_name, last_name)
            }
          })
          .catch(error => {
            console.log(error)
            this.JsonHandler(
              false,
              `Unable to save this profile. Check your selections and try again.`,
            )
            console.log(error.toString())
          })
      }
    } else {
      this.JsonHandler(
        false,
        'Please add a first, last, and either email address/mobile number to continue.',
      )
    }
  }

  // EditUser
  EditUser(userId: number, params: Object, token: string, first_name: string, last_name: string) {
    AdminActions.postAdminMember(userId, params, token)
      .then(json => {
        if (json.legacy_user_id !== null && json.legacy_user_id !== undefined) {
          this.JsonHandler(true, `Successfully Updated ${first_name} ${last_name}`)
        } else if (json.non_field_errors[0] !== null && json.non_field_errors[0] !== undefined) {
          this.JsonHandler(false, `${json.non_field_errors[0]}`)
        } else {
          this.JsonHandler(
            false,
            `Unable to save this profile. Check your selections and try again.`,
          )
        }
      })
      .catch(error => {
        console.log(error)
        this.JsonHandler(false, `Unable to save this profile. Check your selections and try again.`)
      })
  }

  // CheckUserRole
  CheckUserRole(userId: number, userRoleId: number) {
    console.log('CHECKING USER ROLE OF ' + userId + ' WITH USER ROLE ' + userRoleId)
    if (userRoleId !== null && userRoleId !== undefined) {
      ProfileActions.putSingleUserRole(userId, userRoleId, this.props.token).then(json => {})
    }
  }

  // Bye
  Bye(message) {
    this.setState({ loading: false })
    this.props.hideModal()
    //This is kind of hacky I will admit, but I did not want to make the Team Members part of the Redux store -Khristan
    //It accomplishes Remounting and thus Reloading the Team Component on the Admin Page
    const originalPath = this.props.history.location
    this.props.history.replace('/page-not-found')
    this.props.history.replace(originalPath)
  }
  // ***********************LETS*PRESS*THE*BUTTON***********************

  //
  // ***********************LETS*CALL*OTHER*FUNCTIONS***********************
  // onClose
  onClose() {
    this.props.hideModal()
  }

  updatePasswordField(password) {
    UserActions.postPasswordStrengthDJAPI(password).then(res => {
      const { aip_standards } = res
      if (aip_standards) {
        this.setState({
          passwordCompliance: aip_standards.aip_percentage,
          passwordRequirements: aip_standards.aip_pw_rules,
          passwordStrength: aip_standards.normalized_strength,
        })
      }
    })
    this.setState({ password })
  }
  changePassword() {
    const { token, userId } = this.props
    const { password, confirmPassword, passwordCompliance } = this.state
    if (confirmPassword === password && passwordCompliance === 100) {
      userChangePassword(password, confirmPassword, token, userId, false).then(res => {
        if (res) {
          toast(res)
          this.setState({
            password: '',
            passwordVisible: false,
            passwordCompliance: 0,
            passwordStrength: 0,
            passwordRequirements: [],
            confirmPassword: '',
          })
        }
      })
    }
  }
  // onUpdateField
  onUpdateField(field: string, value: string) {
    this.setState({ apiMessage: '', formError: false, formModified: true })
    const newState = {}
    if (field === 'user_mobile') {
    }
    if (field === 'first_name' || field === 'last_name') {
      newState[field] = value.target.value.replace(/[^a-zA-Z\s`-]/g, '')
    } else {
      newState[field] = value.target.value
    }
    this.setState(newState)
  }
  // ***********************LETS*CALL*OTHER*FUNCTIONS***********************

  //
  // ***********************LETS*SETUP*CONTAINERS***********************
  // MainModalContent
  MainModalContent(userAdd: boolean, formError: boolean, apiMessage: string, loading: boolean) {
    const { resetAttempted, apiMessageIsPositive, userRoleId, formModified } = this.state
    const first_nameBoolean = this.state.first_name !== ''
    const last_nameBoolean = this.state.last_name !== ''
    const mobileBoolean = this.state.user_mobile !== ''
    const emailBoolean = this.state.user_email_address !== ''
    const emailIsValid = emailBoolean && Helper.CheckEmailValidation(this.state.user_email_address)
    const mobileIsValid = mobileBoolean && Helper.CheckMobileValidation(this.state.user_mobile)
    const total =
      first_nameBoolean &&
      last_nameBoolean &&
      emailIsValid &&
      userRoleId != null &&
      userRoleId != '' &&
      userRoleId != 0

    return (
      <div className="row">
        <div className="account-settings-form">
          <h1>{userAdd ? 'Add Team Member' : 'Edit Team Member'}</h1>
          <hr />
          <h3>General</h3>
          <div className="accountSettings-form-first">{this.FormFirst()}</div>
          <hr />
          <h3>Alerts</h3>
          <div className="accountSettings-form-second">{this.FormThird()}</div>
          <hr />
          <h3>Advanced</h3>
          <div className="accountSettings-form-second">{this.FormSecond()}</div>
          <div className="" align="center">
            <span style={apiMessageIsPositive ? { color: '#3CB54A' } : { color: '#F83030' }}>
              {formError ? apiMessage : ''}
            </span>
            {formError ? <br /> : ''}
            {formError ? <br /> : ''}

            <div align="right">
              {/* only show reset password on edit */

              !userAdd ? (
                <React.Fragment>
                  <button
                    className="accountSettings-btn-reset"
                    disabled={resetAttempted}
                    onClick={() => {
                      this.setState({ resetAttempted: true })
                      initiateResetPassword(this.state.user_email_address)
                        .then(response => {
                          if (response.status === 200) {
                            this.setState({
                              resetSuccessful: true,
                            })
                            return response.json()
                          } else {
                            toast(
                              'There was an error sending password reset link. Please try again later.',
                            )
                            this.setState({ resetAttempted: false })
                          }
                        })
                        .then(res => {
                          if (res && res.detail) {
                            toast(res.detail, {
                              autoClose: 7000,
                            })
                          }
                        })
                    }}
                  >
                    Send Reset Password Link
                  </button>
                </React.Fragment>
              ) : null}
              <button
                className="accountSettings-btn-save"
                onClick={this._onSubmit}
                disabled={formError || loading || !total}
              >
                Save
              </button>
              <button className="accountSettings-btn-close" onClick={this._onClose}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  // ChangePasswordForm() {
  //   const { userId, allowPasswordChange, userEdit } = this.props
  //   const {
  //     password,
  //     passwordRequirements,
  //     passwordCompliance,
  //     passwordVisible,
  //     confirmPassword,
  //   } = this.state
  //   if (userId && allowPasswordChange && userEdit) {
  //     let passwordComplianceText = ''
  //     let passwordComplianceClass = ''
  //     if (passwordCompliance <= 33) {
  //       passwordComplianceText = 'Poor'
  //       passwordComplianceClass = 'password-compliance-poor'
  //     } else if (passwordCompliance > 33 && passwordCompliance <= 66) {
  //       passwordComplianceText = 'Good'
  //       passwordComplianceClass = 'password-compliance-good'
  //     } else if (passwordCompliance > 66 && passwordCompliance <= 99) {
  //       passwordComplianceText = 'Great'
  //       passwordComplianceClass = 'password-compliance-great'
  //     } else if (passwordCompliance >= 100) {
  //       passwordComplianceText = 'Perfect'
  //       passwordComplianceClass = 'password-compliance-perfect'
  //     }
  //     return (
  //       <div>
  //         <div className="accountSettings-form-fourth">
  //           <div id="changePass-btn" onClick={()=>{initiateResetPassword(this.state.user_email_address)}}>Send Use Password Reset Link</div>
  //           <Collapsible
  //             trigger={<label id="changePass-btn">Change Password</label>}
  //             transitionTime={150}
  //           >
  //             <div className="accountSettings-password-fields">
  //               <div className="double-input-box">
  //                 <label>New Password</label>
  //                 <input
  //                   type={passwordVisible ? 'text' : 'password'}
  //                   onChange={e => {
  //                     this.updatePasswordField(e.target.value)
  //                   }}
  //                   value={password}
  //                 />
  //               </div>
  //               <div className="double-input-box">
  //                 <label>Confirm Password</label>
  //                 <input
  //                   type={passwordVisible ? 'text' : 'password'}
  //                   onChange={e => {
  //                     this.setState({ confirmPassword: e.target.value })
  //                   }}
  //                   value={confirmPassword}
  //                   disabled={!password.length > 0}
  //                 />
  //               </div>
  //             </div>
  //             <div className="accountSettings-password-controls">
  //               <div
  //                 className={`accountSettings-password-control ${
  //                   passwordVisible ? 'accountSettings-password-control-selected' : ''
  //                 }`}
  //                 onClick={() => {
  //                   this.setState(prevState => {
  //                     return { passwordVisible: !prevState.passwordVisible }
  //                   })
  //                 }}
  //               >
  //                 <IconEyeOpen />
  //               </div>
  //               <br />
  //               <button
  //                 title="Changet"
  //                 className="accountSettings-password-submit"
  //                 onClick={() => {
  //                   this.changePassword()
  //                 }}
  //                 type="button"
  //                 disabled={
  //                   password !== confirmPassword ||
  //                   passwordCompliance < 100 ||
  //                   password.length === 0
  //                 }
  //               >
  //                 Save
  //               </button>
  //             </div>
  //             <div className={`${password.length === 0 ? ' hidden' : ''}`}>
  //               <div className="accountSettings-password-compliance-grade-wrapper">
  //                 <label>{passwordComplianceText}</label>
  //                 <div
  //                   className={`accountSettings-password-compliance-grade ${passwordComplianceClass}`}
  //                 />
  //               </div>
  //               <br />
  //               <div className="accountSettings-password-tips">
  //                 <ul>
  //                   {passwordRequirements.map(rq => (
  //                     <li>{rq}</li>
  //                   ))}
  //                 </ul>
  //               </div>
  //             </div>
  //           </Collapsible>
  //         </div>
  //       </div>
  //     )
  //   }
  //   return <React.Fragment />
  // }
  //
  // FormFirst
  FormFirst() {
    const teamBoolean = TeamEditInputSeed.team_mem_edit && TeamEditInputSeed.team_mem_edit.length
    return (
      <span>
        {teamBoolean &&
          TeamEditInputSeed.team_mem_edit.map(d => {
            if (d.name !== 'password' && d.name !== 'passwordConfirmed') {
              return (
                <div
                  className={
                    'double-input-box ' +
                    (d.name === 'user_email_address' || d.name === 'crm_email' ? 'full-width' : '')
                  }
                  key={d.name}
                >
                  <label>
                    {d.placeholder}{' '}
                    {d.name !== 'user_phone' && d.name !== 'user_mobile' && d.name !== 'crm_email'
                      ? '*'
                      : ''}{' '}
                    {this.state[d.value] != this.state.formBaseline[d.value] ? <IconDot /> : null}
                    {/*d.name === 'user_email_address' &&
                    !this.state.emailTipVisible &&
                    !this.props.userAdd ? (
                      <button
                        type="button"
                        className="edit-trunc-btn"
                        onClick={() => {
                          this.setState({ emailTipVisible: true })
                        }}
                      >
                        <EditIcon />
                      </button>
                    ) : null*/}
                    {d.name === 'user_email_address' && this.state.emailTipVisible ? (
                      <div id="accountSettings-emailerror">
                        <i>Updating a team member's email will also update their username!</i>
                      </div>
                    ) : null}
                    {d.name == 'user_phone' || d.name == 'user_mobile' ? (
                      <MaskedInput
                        mask={[
                          '(',
                          /[1-9]/,
                          /\d/,
                          /\d/,
                          ')',
                          ' ',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        className={
                          'form-control ' + (d.name == 'user_email_address' ? 'edit-trunc' : '')
                        }
                        ref={text => {
                          this._inputEditUser = text
                        }}
                        id={`form-${d.name}`}
                        type="text"
                        name={d.name}
                        onChange={text => this._onUpdateField(d.value, text)}
                        placeholder={d.placeholder}
                        value={this.state[d.value]}
                        disabled={
                          (d.name == 'user_email_address' &&
                            !this.props.userAdd &&
                            !this.state.emailTipVisible) ||
                          (d.name == 'user_mobile' && !this.props.userAdd)
                        }
                      />
                    ) : (
                      <input
                        className={
                          'form-control ' + (d.name == 'user_email_address' ? 'edit-trunc' : '')
                        }
                        ref={text => {
                          this._inputEditUser = text
                        }}
                        id={`form-${d.name}`}
                        type="text"
                        name={d.name}
                        onChange={text => this._onUpdateField(d.value, text)}
                        placeholder={d.placeholder}
                        value={this.state[d.value]}
                        disabled={
                          d.name == 'user_email_address' &&
                          !this.props.userAdd &&
                          !this.state.emailTipVisible
                        }
                      />
                    )}
                  </label>
                </div>
              )
            }
          })}
      </span>
    )
  }

  // FormSecond
  FormSecond() {
    const { isAdmin, userAdd } = this.props

    return (
      <span>
        {this.DropdownHandler(
          'Default Store',
          'store',
          this.state.storeName,
          this.state.storeList,
          this.state.dropStore,
        )}
        {(!isAdmin || userAdd) &&
          this.DropdownHandler(
            'User Role *',
            'role',
            this.state.userRoleName,
            this.state.userRoleList,
            this.state.dropRole,
          )}
      </span>
    )
  }

  // FormThird
  FormThird() {
    const { send_mobile_alerts, send_email_alerts, viewFrequency } = this.state
    return (
      <div className="accountSettings-textalert-fieldset">
        <input
          type="checkbox"
          name="accountSettings-textAlertIsEnabled"
          onClick={() => this.setState({ send_email_alerts: !send_email_alerts })}
          id="accountSettings-emailEnable"
          defaultChecked={send_email_alerts}
        />
        <label className="txt-shrink" htmlFor="accountSettings-emailEnable">
          Send me email alerts when sent packets are viewed.
        </label>
        <br />
        <input
          type="checkbox"
          name="accountSettings-textAlertIsEnabled"
          onClick={() => this.setState({ send_mobile_alerts: !send_mobile_alerts })}
          id="accountSettings-textEnable"
          defaultChecked={send_mobile_alerts}
        />
        <label className="txt-shrink" htmlFor="accountSettings-textEnable">
          Send me text alerts when sent packets are viewed.
        </label>
        <br />
        <br />
        <h6>Alert Frequency</h6>
        <input
          disabled={!send_mobile_alerts && !send_email_alerts}
          type="radio"
          name="accountSettings-textAlertsReceiveAll"
          value="true"
          defaultChecked={viewFrequency === 'all'}
          id="accountSettings-txtSend-all"
          onClick={() => this.setState({ viewFrequency: 'all' })}
        />
        <label className="txt-shrink" htmlFor="accountSettings-txtSend-all">
          Send me email and text alerts <b>every time</b> a sent packet is viewed
          <br />
          or a packet price changes
        </label>
        <input
          disabled={!send_mobile_alerts && !send_email_alerts}
          type="radio"
          name="accountSettings-textAlertsReceiveAll"
          value="true"
          defaultChecked={viewFrequency === 'first'}
          onClick={() => this.setState({ viewFrequency: 'first' })}
          id="accountSettings-txtSend-first"
        />
        <label className="txt-shrink" htmlFor="accountSettings-txtSend-first">
          Send me email and text alerts <b>only the first time</b> a sent packet is viewed.
        </label>
      </div>
    )
  }

  addFirstOption(type) {
    if (type === 'user_mobile') {
      return (
        <option key="0" value="Choose Mobile Provider---null">
          Choose Mobile Provider
        </option>
      )
    } else if (type === 'role') {
      return (
        <option key="0" value="Choose Role---null">
          Choose Role
        </option>
      )
    } else if (type === 'store') {
      return null
    } else {
      return null
    }
  }

  //
  // DropdownHandler
  DropdownHandler(
    title: string,
    type: string,
    default_name: string,
    list: Object,
    dropdown: Object,
  ) {
    if (type === 'store') {
      console.log('DEFAULT NAME')
      console.log(default_name)
      console.log('DROPDOWN VALUE')
      console.log(dropdown)
    }
    return (
      <div className="double-input-box">
        <label>
          <div align="left">{title}</div>

          <select
            onChange={(event: Object) => {
              if (type === 'user_mobile') {
                this.setState({
                  formError: false,
                })
              } else if (type === 'role') {
                this.setState({
                  formError: false,
                  dropRole: event.target.value,
                  userRoleId:
                    event.target.value.split('---')[1] != 'null'
                      ? event.target.value.split('---')[1]
                      : null,
                })
              } else if (type === 'store') {
                this.setState({
                  formError: false,
                  dropStore: event.target.value,
                  storeId: event.target.value.split('---')[1],
                })
              }
            }}
            placeholder="Account Settings"
            name={`accountSettings-${type}`}
            value={dropdown}
            className="form-control"
            disabled={dropdown == ''}
          >
            {this.addFirstOption(type)}
            {list &&
              list.length > 0 &&
              list.map(d => {
                if (type === 'role') {
                  return (
                    <option key={d.role_id} value={`${d.role_name}---${d.role_id}`}>
                      {d.role_name}
                    </option>
                  )
                } else if (type === 'store') {
                  const store = `${d.store_type === 1 ? 'New' : 'Pre-Owned'} ${
                    d.company_store_name
                  }---${d.company_store_id}`
                  return (
                    <option key={store} value={store}>
                      {`${d.company_store_name} (${d.store_type === 1 ? 'New' : 'Pre-Owned'})`}
                    </option>
                  )
                }
              })}
          </select>
        </label>
      </div>
    )
  }
  // ***********************LETS*SETUP*CONTAINERS***********************

  props: Props
  _onClose: Function // function
  _onUpdateField: Function // function
  _onSubmit: Function // function
  _inputEditUser: Function // ref

  render() {
    const { userAdd } = this.props
    const { formError, apiMessage, loading, dropRole, dropStore } = this.state
    let content = <LoadingSpinner loading wheel />
    const dropdownsLoaded = dropStore != '' && dropStore != ''
    console.log('THIS IS THE STATE')
    console.log(this.state)
    if (!loading && dropdownsLoaded) {
      content = this.MainModalContent(userAdd, formError, apiMessage, loading)
    }
    return <Modal>{content}</Modal>
  }
}

// ***********************LETS*EXPORT*ADMIN*TEAM*MODAL***********************
function mapStateToProps(state: Object) {
  return {
    allowPasswordChange:
      state.authentication.user.user.is_staff || state.authentication.user.user.is_superuser,
    userId: state.modal.props.user_id,
    storeId: state.modal.props.store_id,
    userEdit: state.modal.props.user_edit,
    userAdd: state.modal.props.user_add,
    token: state.modal.props.token,
    isAdmin: state.modal.props.isAdmin,
  }
}
const TeamMemberEditModal = withRouter(TeamMemberEditModalComponent)
export default connect(mapStateToProps, { hideModal })(TeamMemberEditModal)
