// general:

// @flow
import React, { Component } from 'react'
// node packages
// --- redux
import { connect } from 'react-redux'
// core files
// --- actions
import PacketActions from '../../../../core/actions/packets/packetactions'
// --- helpers
import Helper from '../../../../core/helpers/platformhelper'
// components
import PacketRepairOrderChild from './children/repairorderchild'
import HTMLModuleChild from './children/htmlmodulechild'
import RateModule from './RateModule'
// helpers
import LoadingSpinner from '../../../helpers/loadingspinner'
//image
import IconExternalLink from '../../../../core/assets/images/icons/icon-external-link'
// css styles
import '../../../styles/universalstyles.scss'
import '../../styles/packetsend.scss'
import WordTrack from '../../../helpers/wordtrack.js'
type Props = {
  moduleOb: Object,
  token: string,
}

class ModulesChild extends Component {
  constructor(props: Props) {
    super(props)
    this.state = {
      moduleOb: this.props.moduleOb,
      moduleImgArr: [],
      repair_order: [],
    }
  }

  state: { moduleOb: Object, moduleImgArr: Object, repair_order: Object }

  componentDidMount() {
    this.mounted = true
    if (this.mounted) {
      const { moduleOb } = this.state
      if (moduleOb !== null && moduleOb !== undefined) {
        if (!moduleOb.djapi_hosted && moduleOb.content_type != 'json') {
          this.onMountSetPNGS(moduleOb)
        } else {
          this.onMountCallDJAPI(moduleOb.url)
        }
      }
    }
  }

  // componentWillReceiveProps
  // - get updated props as they come in.
  UNSAFE_componentWillReceiveProps(newProps: Props) {
    const { moduleOb } = newProps
    this.setState({ moduleOb })
    if (moduleOb !== this.props.moduleOb) {
      if (!moduleOb.djapi_hosted && moduleOb.content_type == 'pdf') {
        this.onMountSetPNGS(moduleOb)
      } else {
        this.onMountCallDJAPI(moduleOb.url)
      }
    }
  }

  // componentWillUnmount
  // - resets all values and states and things.
  componentWillUnmount() {
    this.mounted = false
    this.setState({ moduleOb: null, moduleImgArr: [], repair_order: [] })
  }

  //
  // ***********************LETS*CALL*ONMOUNT*SETUP***********************
  // onMountSetPNGS
  onMountSetPNGS(moduleOb: Object) {
    const url = moduleOb.url
    if (url !== null && url !== undefined && url !== '') {
      if (Helper.CheckMobileScreenOrientation() && moduleOb.content_type === 'pdf') {
        const array = []
        const count = 0
        if (url.search('-01.pdf')) {
          const splitUrl = url.split('.pdf')[0]
          this.CallMe(splitUrl, count, array)
        } else {
          array.push(url)
          this.setState({ moduleImgArr: array })
        }
      }
    }
  }

  // onMountCallDJAPI
  onMountCallDJAPI(url: string) {
    PacketActions.getPacketRepairOrder(url, this.props.token).then(json => {
      if (json && json.length > 0) {
        this.setState({ repair_order: json })
      }
    })
  }
  // ***********************LETS*CALL*ONMOUNT*SETUP***********************

  //
  // ***********************LETS*DO*FUNCTIONS***********************
  // CallMe
  CallMe(url: string, count: number, array: Object) {
    count++
    if (this.CheckS3(`${url}_page_${count}.png`)) {
      array.push(`${url}_page_${count}.png`)
      this.CallMe(url, count, array)
    } else if (array && array.length === 0) {
      array.push(`${url}_page_${count}.png`)
      this.setState({ moduleImgArr: array })
    } else {
      this.setState({ moduleImgArr: array })
    }
  }

  // CheckS3
  CheckS3(url: string) {
    const https = new XMLHttpRequest()
    https.open('GET', url, false)
    https.setRequestHeader('Cache-Control', 'no-cache')
    https.send()
    return https.status !== 403
  }
  // ***********************LETS*DO*FUNCTIONS***********************

  //
  // ***********************LETS*DO*TYPES***********************
  // PDFContentType
  PDFContentType(url: string, label: string) {
    return (
      <object
        data={url}
        key={label}
        className="packetSendModulePdfStyle"
        aria-label={`${label}-pdf-img`}
        type="application/pdf"
        onError={() => {
          console.log('IT HAPPENED')
        }}
      />
    )
  }

  // IMGArrayContentType
  IMGArrayContentType(moduleImgArr: Object) {
    let counter = 0
    return (
      <div className="mobile-pdf-png-container">
        {moduleImgArr &&
          moduleImgArr.length > 0 &&
          moduleImgArr.map(d => (
            <img
              key={counter++}
              src={d}
              alt={`mobile-pfd-view-${counter}`}
              className="mobile-pdf-png-replacement"
            />
          ))}
      </div>
    )
  }

  // YoutubeContentType
  YoutubeContentType(url: string) {
    return (
      <div key={url} style={{ position: 'relative', paddingTop: '56.25%', width: '90%' }}>
        <iframe
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          src={url}
          allowFullScreen
        />
      </div>
    )
  }

  // HTMLContentType(s)
  HTMLContentType(url: string, module_type: string) {
    if (module_type === 'html_url') {
      // if 'html_url'
      return <HTMLModuleChild module_url={url} />
    } else {
      // if 'html'
      return <div dangerouslySetInnerHTML={{ __html: url }} />
    }
  }

  // GP3ContentType
  GP3ContentType(moduleOb) {
    return (
      <a key={moduleOb.url} href={moduleOb.url} target="_blank">
        {moduleOb.label}
      </a>
    )
  }

  // HTML5ContentType
  HTML5ContentType(url: string) {
    return (
      <div key={url} style={{ position: 'relative', paddingTop: '56.25%', width: '90%' }}>
        <video
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          controls
        >
          <source src={url} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      </div>
    )
  }

  BrochureDownloadLink(url: string) {
    return (
      <a key={url} href={url}>
        {' '}
        Click Here to view Brochure{' '}
      </a>
    )
  }

  // IMGSingleContentType
  IMGSingleContentType(url: string) {
    return <img key={url} src={url} width="90%" alt={`mobile-pfd-view-${url}`} />
  }
  // ***********************LETS*DO*TYPES***********************
  JSONContentType(url) {
    return <div>JSON</div>
  }
  IFrameContentType(url) {
    return (
      <iframe key={url} src={url} className="packetSendModuleIframeStyle" allowFullScreen></iframe>
    )
  }
  props: Props

  render() {
    const { moduleOb } = this.state

    if (moduleOb !== null && moduleOb !== undefined) {
      // states
      const { moduleImgArr, repair_order } = this.state

      let mainContent = <LoadingSpinner loading wheel />
      // NOTE: moduleOb.content_type can be:
      // pdf
      // youtube_video
      // html5_video
      // 3gp
      // html
      if (!moduleOb.djapi_hosted) {
        if (moduleOb.content_type === 'pdf') {
          if (Helper.CheckMobileScreenOrientation()) {
            if (moduleOb.module_id == 16) {
              mainContent = this.BrochureDownloadLink(moduleOb.url)
            } else {
              mainContent = this.IMGArrayContentType(moduleImgArr)
            }
          } else {
            mainContent = this.PDFContentType(moduleOb.url, moduleOb.label)
          }
        } else if (moduleOb.content_type === 'html5_video') {
          mainContent = this.HTML5ContentType(moduleOb.url)
        } else if (moduleOb.content_type === 'youtube_video') {
          mainContent = this.YoutubeContentType(moduleOb.url)
        } else if (moduleOb.content_type === '3pa') {
          mainContent = this.GP3ContentType(moduleOb)
        } else if (moduleOb.content_type === 'html' || moduleOb.content_type === 'html_url') {
          mainContent = this.HTMLContentType(moduleOb.url, moduleOb.content_type)
        } else if (moduleOb.content_type === 'image') {
          mainContent = this.IMGSingleContentType(moduleOb.url)
        } else if (moduleOb.content_type === 'iframe') {
          mainContent = this.IFrameContentType(moduleOb.url)
        } else if (moduleOb.content_type === 'json' && moduleOb.module_id === 9) {
          mainContent = <RateModule url={moduleOb.url} token={this.props.token} />
        } else {
          mainContent = this.PDFContentType(moduleOb.url, moduleOb.label)
        }
      } else {
        if (moduleOb.content_type === 'iframe') {
          mainContent = this.IFrameContentType(moduleOb.url)
        } else {
          mainContent = (
            <span>
              {repair_order &&
                repair_order.length > 0 &&
                repair_order.map(d => (
                  <PacketRepairOrderChild repair={d} key={d.repair_order_number} />
                ))}
            </span>
          )
        }
      }

      return (
        <div align="center" className="packetSendMiddleContentStyle">
          <b style={{ fontSize: '1.5em' }}>
            {moduleOb.label}
            {(moduleOb.module_id === 7 ||
              moduleOb.module_id === 4 ||
              moduleOb.module_id === 50) && <WordTrack isMSRP={moduleOb.module_id === 7} />}
            {moduleOb.content_type === 'image' ? (
              <a
                href={moduleOb.url}
                target="_blank"
                rel="noreferrer"
                className="packetSendButtonsStyle"
              >
                <IconExternalLink className="icon-external-link" />
              </a>
            ) : null}
          </b>

          {/*<div className="packetSendDescriptionStyle">{moduleOb.description}</div>*/}
          <br />
          {mainContent}
        </div>
      )
    }

    return null
  }
}

const mapStateToProps = state => ({
  moduleOb: state.packetstash.single_module_info,
})
export default connect(mapStateToProps, {})(ModulesChild)
