import React, { useState } from 'react'
import RichTextEditor from 'react-rte'
import UserEmailTemplates from './userEmailTemplates'
import ToolTip from '../../helpers/tooltip'

const TabContent = props => {
  const { selectedTab, handleFormChanges, userProfile, errors, zoomRequestError } = props

  const Alerts = ({ userProfile }) => {
    return (
      <div className="shown-section">
        <h2>Alerts</h2>
        <div className="subform-column">
          <div className="checkbox-container">
            <label className="form-text" htmlFor="send_email_alerts">
              <input
                type="checkbox"
                name="email_alerts"
                id="send_email_alerts"
                className="text-input"
                onChange={e => handleFormChanges(e, !userProfile.send_email_alerts)}
                value={userProfile.send_email_alerts}
                checked={userProfile.send_email_alerts}
                data-group="profile"
              />
              Send me email alerts when sent packets are viewed
            </label>
          </div>
          <br />
          <div className="checkbox-container">
            <label className="form-text" htmlFor="send_mobile_alerts">
              <input
                type="checkbox"
                name="mobile_alerts"
                id="send_mobile_alerts"
                className="text-input"
                onChange={e => handleFormChanges(e, !userProfile.send_mobile_alerts)}
                value={userProfile.send_mobile_alerts}
                checked={userProfile.send_mobile_alerts}
                data-group="profile"
              />
              Send me text alerts when sent packets are viewed
            </label>
          </div>
          <br />
          <h4>
            <b>Alert Frequency</b>
          </h4>
          <div className="form-radio-options-vertical">
            <label className="form-text" htmlFor="view_frequency1">
              <input
                type="radio"
                name="view_frequency"
                id="view_frequency1"
                className="text-input"
                onChange={e => handleFormChanges(e, 'all')}
                value={userProfile.view_frequency}
                checked={userProfile.view_frequency === 'all' || null}
                disabled={!(userProfile.send_email_alerts || userProfile.send_mobile_alerts)}
                data-group="profile"
              />
              <p>
                Send me email and text alerts <b>every time</b> a sent packet is viewed or a packet
                price changes
              </p>
            </label>
            <br />
            <label className="form-text" htmlFor="view_frequency2">
              <input
                type="radio"
                name="view_frequency"
                id="view_frequency2"
                className="text-input"
                onChange={e => handleFormChanges(e, 'first')}
                value={userProfile.view_frequency}
                checked={userProfile.view_frequency === 'first'}
                disabled={!(userProfile.send_email_alerts || userProfile.send_mobile_alerts)}
                data-group="profile"
              />
              <p>
                Send me email and text alerts <b>only the first time</b> a sent packet is viewed
              </p>
            </label>
            <br />
          </div>
        </div>
      </div>
    )
  }

  const PacketMessages = ({ userProfile, errors }) => {
    const [defaultTemplate, setDefaultTemplate] = useState(false)

    const renderPacketMessagesContent = () => {
      return (
        <div className="emailMessage-container">
          <div className="disclaimer">
            On this page you can edit your default email template used on packet shares
          </div>
          <hr />
          <div className="emailMessage-signature-grid">
            <h3>Email Message</h3>
            <div className="tabContent-checkbox">
              <label htmlFor="defaultMessage">
                <input
                  type="radio"
                  name="emailMessageIsCustom"
                  id="defaultMessage"
                  onChange={e => handleFormChanges(e, !userProfile.emailMessageIsCustom)}
                  value={!userProfile.emailMessageIsCustom}
                  checked={!userProfile.emailMessageIsCustom}
                  data-group="profile"
                />
                Default
              </label>
              &nbsp;&nbsp;&nbsp;
              <label htmlFor="customMessage">
                <input
                  type="radio"
                  name="emailMessageIsCustom"
                  id="customMessage"
                  onChange={e => handleFormChanges(e, !userProfile.emailMessageIsCustom)}
                  value={userProfile.emailMessageIsCustom}
                  checked={userProfile.emailMessageIsCustom}
                  data-group="profile"
                />
                Custom
              </label>
            </div>
            {userProfile.emailMessageIsCustom ? (
              <div className="text-area-container">
                <RichTextEditor
                  value={userProfile.send_message}
                  className="text-box"
                  onChange={value => handleFormChanges('send_message', value, 'profile')}
                  data-group="profile"
                />
                {errors.send_message ? (
                  <div className="form-container">
                    <div className="error">{errors.send_message}</div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          <div className="emailMessage-signature-grid">
            <h3>Email Signature</h3>
            <div className="tabContent-checkbox">
              <label htmlFor="defaultSignature">
                <input
                  type="radio"
                  name="emailSignatureIsCustom"
                  id="defaultSignature"
                  onChange={e => handleFormChanges(e, false)}
                  value={!userProfile.emailSignatureIsCustom}
                  checked={!userProfile.emailSignatureIsCustom}
                  data-group="profile"
                />
                Default
              </label>
              &nbsp;&nbsp;&nbsp;
              <label htmlFor="customSignature">
                <input
                  type="radio"
                  name="emailSignatureIsCustom"
                  id="customSignature"
                  onChange={e => handleFormChanges(e, true)}
                  value={userProfile.emailSignatureIsCustom}
                  checked={userProfile.emailSignatureIsCustom}
                  data-group="profile"
                />
                Custom
              </label>
            </div>
            {userProfile.emailSignatureIsCustom ? (
              <div className="text-area-container">
                <RichTextEditor
                  value={userProfile.signature}
                  className="text-box"
                  onChange={value => handleFormChanges('signature', value, 'profile')}
                  data-group="profile"
                />
                {errors.signature ? (
                  <div className="form-container">
                    <div className="error">{errors.signature}</div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      )
    }

    return (
      <div className="shown-section">
        <h2 className="packetMessages">Packet Messages</h2>
        <div className="tabContent-checkbox packetMessages">
          <label htmlFor="defaultTemplate">
            <input
              type="radio"
              name="default-v-custom"
              id="defaultTemplate"
              onChange={() => setDefaultTemplate(!defaultTemplate)}
              value={!defaultTemplate}
              checked={!defaultTemplate}
            />
            Default Template
          </label>
          &nbsp;&nbsp;&nbsp;
          <label htmlFor="userTemplate">
            <input
              type="radio"
              name="default-v-custom"
              id="userTemplate"
              onChange={() => setDefaultTemplate(!defaultTemplate)}
              value={defaultTemplate}
              checked={defaultTemplate}
            />
            New Template
          </label>
        </div>
        {defaultTemplate ? <UserEmailTemplates /> : renderPacketMessagesContent()}
      </div>
    )
  }

  const Privacy = ({ userProfile }) => {
    return (
      <div className="shown-section">
        <h2 className="packetMessages">Privacy</h2>
        <div className="checkbox-container packetMessagesPrivacy">
          <label className="form-text" htmlFor="enable_privacy_mobile_number">
            <input
              type="checkbox"
              name="privacy_mobile_number"
              id="enable_privacy_mobile_number"
              onChange={e => handleFormChanges(e, !userProfile.enable_privacy_mobile_number)}
              value={userProfile.enable_privacy_mobile_number}
              checked={userProfile.enable_privacy_mobile_number}
              data-group="privacyPreferences"
            />
            Keep mobile number private
          </label>
          <ToolTip
            onHover
            tip={
              <React.Fragment>
                This prevents other users from seeing
                <br />
                your Mobile Phone Number
              </React.Fragment>
            }
          />
        </div>
      </div>
    )
  }

  const Zoom = ({ userProfile, zoomRequestError }) => {
    return (
      <div className="shown-section">
        <h2 className="packetMessages">Zoom</h2>
        <div className="tabContent-checkbox">
          <label htmlFor="enable_zoom_request_email_notification">
            <input
              type="checkbox"
              name="email_notification"
              id="enable_zoom_request_email_notification"
              onChange={e =>
                handleFormChanges(e, !userProfile.enable_zoom_request_email_notification)
              }
              value={userProfile.enable_zoom_request_email_notification}
              checked={userProfile.enable_zoom_request_email_notification}
              data-group="privacyPreferences"
            />
            Zoom Email Notification
          </label>
        </div>
        <br />
        <div className="tabContent-checkbox">
          <label htmlFor="enable_zoom_request_sms_notification">
            <input
              type="checkbox"
              name="sms_notification"
              id="enable_zoom_request_sms_notification"
              onChange={e =>
                handleFormChanges(e, !userProfile.enable_zoom_request_sms_notification)
              }
              value={userProfile.enable_zoom_request_sms_notification}
              checked={userProfile.enable_zoom_request_sms_notification}
              data-group="privacyPreferences"
            />
            Zoom SMS Notification
          </label>
        </div>
        {zoomRequestError ? (
          <div className="form-container">
            <div className="error">SMS or Email must be selected</div>
          </div>
        ) : null}
        <br />
        <div className="tabContent-checkbox">
          <label htmlFor="enable_zoom_request_push_notification">
            <input
              type="checkbox"
              name="push_notification"
              id="enable_zoom_request_push_notification"
              onChange={e =>
                handleFormChanges(e, !userProfile.enable_zoom_request_push_notification)
              }
              value={userProfile.enable_zoom_request_push_notification}
              checked={userProfile.enable_zoom_request_push_notification}
              data-group="privacyPreferences"
            />
            Zoom Push Notification
          </label>
        </div>
      </div>
    )
  }

  const Accessibility = ({ userProfile }) => {
    return (
      <div className="shown-section">
        <h2 className="packetMessages">
          Accessibility
          <ToolTip
            onHover
            tip={
              <React.Fragment>
                Color vision deficiency increases the contrast ratio of adjacent colors, sitewide.
              </React.Fragment>
            }
          />
        </h2>
        <div className="tabContent-checkbox">
          <label htmlFor="cvd_mode">
            <input
              type="checkbox"
              name="cvd"
              id="cvd_mode"
              onChange={e => handleFormChanges(e, !userProfile.cvd_mode)}
              value={userProfile.cvd_mode}
              checked={userProfile.cvd_mode}
              data-group="privacyPreferences"
            />
            Enable CVD Mode
          </label>
        </div>
        <br />
        <div className="tabContent-checkbox">
          <label htmlFor="dark_mode_webapp">
            <input
              type="checkbox"
              name="dark_mode"
              id="dark_mode_webapp"
              onChange={e => handleFormChanges(e, !userProfile.dark_mode_webapp)}
              value={userProfile.dark_mode_webapp}
              checked={userProfile.dark_mode_webapp}
              data-group="privacyPreferences"
            />
            Dark Mode
          </label>
        </div>
      </div>
    )
  }

  const PacketSend = ({ userProfile }) => {
    return (
      <div className="shown-section">
        <h2 className="packetMessages">
          Packet Send
          <ToolTip
            onHover
            tip={
              <React.Fragment>
                This decides whether the store shown on a packet share is the salesperson's store or
                the store the vehicle is currently located in.
              </React.Fragment>
            }
          />
        </h2>
        <div className="tabContent-checkbox">
          <label htmlFor="vdp_store_follow_user">
            <input
              type="checkbox"
              name="store_follow_user"
              id="vdp_store_follow_user"
              onChange={e => handleFormChanges(e, !userProfile.vdp_store_follow_user)}
              value={userProfile.vdp_store_follow_user}
              checked={userProfile.vdp_store_follow_user}
              data-group="privacyPreferences"
            />
            Store on VDP Follow User Default Store
          </label>
        </div>
      </div>
    )
  }

  switch (selectedTab) {
    case 'Alerts':
      return <Alerts userProfile={userProfile} />
    case 'Packet Messages':
      return <PacketMessages userProfile={userProfile} errors={errors} />
    case 'Privacy':
      return <Privacy userProfile={userProfile} />
    case 'Zoom':
      return <Zoom userProfile={userProfile} zoomRequestError={zoomRequestError} />
    case 'Accessibility':
      return <Accessibility userProfile={userProfile} />
    case 'Packet Send':
      return <PacketSend userProfile={userProfile} />
  }
}

export { TabContent }
